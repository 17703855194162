import { Alert } from 'react-bootstrap';

export function resolveApiErrorCode(errors) {
    const errorList = [];

    errors.forEach((error, index) => {
        switch(error.code) {
            case 'E_INVALID-ACCESS-TOKEN':
                localStorage.removeItem('account');
                window.location.href = '/login';
            break;
            case 'E_RESOURCE-LIMIT-REACHED':
                errorList.push(<Alert key={index} variant="danger" className="mb-3">{ error.message }</Alert>);
            break;
            default:
                console.log('Unresolved API error', error);
            break;
        }
    });

    return errorList;
}
