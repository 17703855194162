import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Select from 'react-select';
import Headroom from "headroom.js";

import useWindowDimensions from '../../utils/useWindowDimensions';
import { config } from '../../config';

function Header(props) {
    const t = props.t;
    const i18n = props.i18n;
    const currentLanguage = i18n.language;

    const languages = [];
    if (config.languages) {
        config.languages.forEach(language => {
        if (language !== 'cimode') {
            languages.push({
                value: language.name,
                label: language.title
            });
        }
      });
    }

    const { width } = useWindowDimensions();
    const navigate = useNavigate();

    const [account] = useState(props.account);
    const [menuOpen, setMenuOpen] = useState(props.menuOpen);
    const [showPopup, setShowPopup] = useState(false);
    const [items, setItems] = useState([]);



    const changeLanguage = (e) => {
        const language = e.value;
        props.i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    }

    const onMenuToggle = () => {
        setMenuOpen(!menuOpen);
        props.handleMenuOpen();
    }

    useEffect(() => {
        var headroom  = new Headroom(document.querySelector('.headroom'), { offset: 80 });
        headroom.init();

        let pathName = window.location.pathname;

        const handlePopup = () => {
            setShowPopup(!showPopup);
        }

        const handlePopupLink = (path) => {
            setShowPopup(!showPopup);
            navigate(path);
        }

        const handleLogout = () => {
            props.handleLogout();
        }

        const updateNavigation = () => {
            if (account) {
                setItems([
                    <li key="workouts">
                        <Link to="/workouts" className={/^\/workout.+/.test(pathName) ? 'active' : ''}>{t('Workouts')}</Link>
                    </li>,
                    <li key="programs">
                        <Link to="/programs" className={/^\/program.+/.test(pathName) ? 'active' : ''}>{t('Programs')}</Link>
                    </li>,
                    <li key="weight">
                        <Link to="/weight" className={/^\/weight/.test(pathName) ? 'active' : ''}>{t('Weight')}</Link>
                    </li>,
                    <li key="stats">
                        <Link to="/stats" className={/^\/stats/.test(pathName) ? 'active' : ''}>{t('Stats')}</Link>
                    </li>,
                    <li key="profile" className="profile d-flex align-items-center justify-content-center">
                        <button onClick={() => handlePopup()}>
                            {account.profile_image &&
                                <img src={config.api.baseUrl + 'uploads/' + account.profile_image} className="d-inline rounded-circle" width={30} height={30} alt={account.profile_image} />
                            }
                            {!account.profile_image &&
                                <div className="default">
                                    <i className="far fa-user" />
                                </div>
                            }
                        </button>
                        {showPopup &&
                            <div className="popup">
                                <button onClick={() => handlePopupLink('/profile')}>{t('Profile')}</button>
                                <button onClick={() => handleLogout()}>{t('Logout')}</button>
                            </div>
                        }
                    </li>,
                ]);
            } else {
                setItems([
                    <li key="about">
                        <HashLink to="/#features">{t('nav.about')}</HashLink>
                    </li>,
                    <li key="pricing">
                        <HashLink to="/#pricing">{t('nav.pricing')}</HashLink>
                    </li>,
                    <li key="contact">
                        <HashLink to="/#contact-us">{t('nav.contact')}</HashLink>
                    </li>,
                    <li key="login" className="main-button">
                        <Link to="/login">{t('Login')}</Link>
                    </li>
                ]);
            }
        }

        updateNavigation();
    }, [props, t, navigate, account, showPopup, setShowPopup]);

    return (
        <header className={`header-area signed-${account ? 'in' : 'out'}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <nav className="main-nav d-flex align-items-center">
                            {width < 768 &&
                                <button className={`menu-toggle${props.menuOpen ? ' active' : ''}`} onClick={onMenuToggle}>
                                    <i className="far fa-bars"></i>
                                </button>
                            }
                            <Link to="/" className="flex-grow-1 logo"><em>W</em>Log</Link>
                            {width >= 768 &&
                                <ul className="nav">
                                    { items }
                                </ul>
                            }
                            {account && width < 768 &&
                                <Link to="/workout-start" className="btn btn-secondary">
                                    <i className="fas fa-plus" />
                                </Link>
                            }
                            {!account && width < 768 &&
                                <Link to="/login" className="btn btn-secondary">
                                    <i className="fas fa-sign-in-alt" />
                                </Link>
                            }
                            <Select
                                className="language-select"
                                classNamePrefix="language-select"
                                value={{ label: currentLanguage, value: currentLanguage }}
                                options={languages}
                                onChange={changeLanguage}
                                isSearchable={false}
                                isMulti={false}
                                placeholder={false}
                            />
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;

