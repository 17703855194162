import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { config } from '../../config';

function NavigationHead(props) {
    const settings = props.settings;

    let navigate = useNavigate();

    const [account] = useState(props.account);
    const [menuOpen, setMenuOpen] = useState(props.menuOpen);

    const emailFallback = settings ? settings.email.split('@')[0] : null;
    const displayName = settings ? (settings.firstName ? settings.firstName + (settings.lastName ? ' ' + settings.lastName : '') : emailFallback) : '';

    const handleLink = (path) => {
        setMenuOpen(!menuOpen);
        props.handleMenuOpen();

        navigate(path);
    }

    if (!account) {
        return (
            <div className="menu-head signed-out" />
        );
    }

    return (
        <div className="menu-head" onClick={(e) => handleLink('/profile')}>
            <div className="profile-img rounded-circle">
                {settings && settings.profile_image &&
                    <img
                        src={config.api.baseUrl + 'uploads/' + settings.profile_image}
                        className="d-inline rounded-circle"
                        width={100}
                        height={100}
                        alt={settings.profile_image}
                    />
                }
                {settings && !settings.profile_image &&
                    <div className="default">
                        <i className="far fa-user" />
                    </div>
                }
            </div>
            <h4>{displayName}</h4>
        </div>
    )
}

export default NavigationHead;
