import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import i18next from 'i18next';
import ICU from 'i18next-icu';
import App from './App';
import * as serviceWorker from './serviceWorker';

import app_en from './locales/en/app.json';
import app_lt from './locales/lt/app.json';

i18next.use(ICU).init({
    interpolation: {
        escapeValue: false
    },
    lng: localStorage.getItem('language') || 'lt',
    supportedLngs: ['lt', 'en'],
    resources: {
        en: {
            app: app_en
        },
        lt: {
            app: app_lt
        },
    },
});

ReactDOM.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>
    </BrowserRouter>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
