import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Navigation(props) {
    const t = props.t;

    let navigate = useNavigate();

    const [account] = useState(props.account);
    const [menuOpen, setMenuOpen] = useState(props.menuOpen);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const handleLink = (path) => {
            setMenuOpen(!menuOpen);
            props.handleMenuOpen();

            navigate(path);
        }

        const handleLogout = () => {
            props.handleMenuOpen();
            props.handleLogout();
        }

        const updateNavigation = () => {
            let pathName = window.location.pathname;

            if (account) {
                setItems([
                    <li key="workouts" className={/^\/workout.+/.test(pathName) ? 'nav-link active' : 'nav-link'}>
                        <button onClick={() => handleLink('/workouts')}>{t('Workouts')}</button>
                    </li>,
                    <li key="programs" className={/^\/program.+/.test(pathName) ? 'nav-link active' : 'nav-link'}>
                        <button onClick={() => handleLink('/programs')}>{t('Programs')}</button>
                    </li>,
                    <li key="weight" className={/^\/weight.+/.test(pathName) ? 'nav-link active' : 'nav-link'}>
                        <button onClick={() => handleLink('/weight')}>{t('Weight')}</button>
                    </li>,
                    <li key="stats" className={/^\/stats.+/.test(pathName) ? 'nav-link active' : 'nav-link'}>
                        <button onClick={() => handleLink('/stats')}>{t('Stats')}</button>
                    </li>,
                    <li key="seperator" className="seperator">
                        <hr />
                    </li>,
                    <li key="about" className="nav-link alt">
                        <HashLink to="/#features">{t('nav.about')}</HashLink>
                    </li>,
                    <li key="pricing" className="nav-link alt">
                        <HashLink to="/#pricing">{t('nav.pricing')}</HashLink>
                    </li>,
                    <li key="contact" className="nav-link alt">
                        <HashLink to="/#contact-us">{t('nav.contact')}</HashLink>
                    </li>,
                    <li key="profile" className={pathName === '/profile' ? 'nav-link alt active' : 'nav-link alt'}>
                        <button onClick={() => handleLink('/profile')}>{t('Profile')}</button>
                    </li>,
                    <li key="logout" className={pathName === '/logout' ? 'nav-link alt active' : 'nav-link alt'}>
                        <button onClick={() => handleLogout()}>{t('Logout')}</button>
                    </li>
                ]);
            } else {
                setItems([
                    <li key="about" className="nav-link">
                        <HashLink to="/#features">{t('nav.about')}</HashLink>
                    </li>,
                    <li key="pricing" className="nav-link">
                        <HashLink to="/#pricing">{t('nav.pricing')}</HashLink>
                    </li>,
                    <li key="contact" className="nav-link">
                        <HashLink to="/#contact-us">{t('nav.contact')}</HashLink>
                    </li>,
                    <li key="login" className="nav-link">
                        <button onClick={() => handleLink('/login')}>{t('Login')}</button>
                    </li>
                ]);
            }
        }

        updateNavigation();
    }, [navigate, account, menuOpen, props, t]);
    return (
        <ul className="nav flex-column">
            { items }
        </ul>
    )
}

export default Navigation;
