import axios from 'axios';
import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';

import useWindowDimensions from '../../utils/useWindowDimensions';
import { config } from '../../config';
import Loading from '../Common/Loading';

function Home(props) {
    const t = props.t;

    const { width } = useWindowDimensions();

    const [loading, setLoading] = useState(false);
    const [account] = useState(props.account);
    const [tabIndex, setTabIndex] = useState(0);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [loadRecaptcha, setLoadRecaptcha] = useState(false);
    const [recaptcha, setRecaptcha] = useState(null);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        handleLoadRecaptcha();
    });

    const handleLoadRecaptcha = () => {
        if (window.recaptcha) {
            return;
        }

        if (loadRecaptcha) {
            const script = document.createElement('script')
            script.src = 'https://www.google.com/recaptcha/api.js';
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setErrors([]);

        if (!recaptcha) {
            setErrors(e => [...e, 'recaptcha']);
            return false;
        }

        const url = config.api.baseUrl + 'contact';
        const post = { name, email, subject, message, recaptcha };

        setLoading(true);
        axios.post(url, post).then(apiResponse => {
            setLoading(false);
            if (apiResponse !== undefined && apiResponse.data.status === 'OK') {
                setName('');
                setEmail('');
                setSubject('');
                setMessage('');
                setRecaptcha(null);
                setErrors(e => [...e, 'sent']);
            }
        }).catch(e => {
            console.log(e);
        });

        return false;
    }

    const Recaptcha = require('react-recaptcha');

    const verifyCallback = (response) => {
        setRecaptcha(response);
    };

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <div className="landing">
            <MetaTags>
                <title>{t('meta.title')}</title>
                <meta property="og:title" content={t('meta.title')} />
            </MetaTags>
            <div className="main-banner" id="top">
                {width >= 768 &&
                    <video autoPlay muted loop id="bg-video">
                        <source src="/images/gym-video.mp4" type="video/mp4" />
                    </video>
                }
                {width < 768 &&
                    <div id="bg-video" className="background" />
                }
                <div className="video-overlay header-text">
                    <div className="caption">
                        <h6>{t('landing.main.h6')}</h6>
                        <h2>{t('landing.main.h2')} <em>W</em>Log</h2>
                        {!account &&
                            <div className="main-button scroll-to-section">
                                <a href="#pricing">{t('landing.main.mainButton')}</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <section className="section" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="section-heading">
                                <h2>{t('landing.features.h2')}</h2>
                                <img src="/images/line-dec.png" width={45} height={15} alt="" />
                                <p>{t('landing.features.p')}</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <ul className="features-items">
                                <li className="feature-item d-flex flex-row align-items-start mb-4">
                                    <div className="left-icon me-4">
                                        <img src="/images/features-first-icon.png" width={100} height={100} alt="Guided programs" />
                                    </div>
                                    <div className="right-content">
                                        <h4>{t('landing.features.programs.h4')}</h4>
                                        <p>{t('features.guidedPrograms.description')}</p>
                                    </div>
                                </li>
                                <li className="d-flex flex-row mb-4 align-items-center">
                                    <div className="left-icon me-4">
                                        <img src="/images/features-first-icon.png" width={100} height={100} alt="Interactive workouts" />
                                    </div>
                                    <div className="right-content">
                                        <h4>{t('landing.features.workouts.h4')}</h4>
                                        <p>{t('features.interactiveWorkouts.description')}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <ul className="features-items">
                                <li className="d-flex flex-row mb-4 align-items-center">
                                    <div className="left-icon me-4">
                                        <img src="/images/features-first-icon.png" width={100} height={100} alt="Weight tracking" />
                                    </div>
                                    <div className="right-content">
                                        <h4>{t('landing.features.weight.h4')}</h4>
                                        <p>{t('features.weightTracking.description')}</p>
                                    </div>
                                </li>
                                <li className="d-flex flex-row mb-4 align-items-center">
                                    <div className="left-icon me-4">
                                        <img src="/images/features-first-icon.png" width={100} height={100} alt="Workout statistics" />
                                    </div>
                                    <div className="flex-grow-1 right-content">
                                        <h4>{t('landing.features.statistics.h4')}</h4>
                                        <p>{t('features.statistics.description')}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section" id="pricing">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="section-heading">
                                <h2>{t('landing.pricing.h2')}</h2>
                                <img src="/images/line-dec.png" width={45} height={15} alt="Line" />
                                <p>{t('landing.pricing.p')}</p>
                            </div>
                        </div>
                    </div>
                    <ul className="nav nav-pills nav-fill">
                        <li className="nav-item">
                            <button className={`${tabIndex === 0 ? 'active' : ''}`} onClick={() => setTabIndex(0)}>
                                <img src="/images/tabs-first-icon.png" width={35} height={35} alt="" />
                                {t('landing.pricing.free.h4')}
                            </button>
                        </li>
                        <li className="nav-item">
                            <button className={`${tabIndex === 1 ? 'active' : ''}`} onClick={() => setTabIndex(1)}>
                                <img src="/images/tabs-first-icon.png" width={35} height={35} alt="" />
                                {t('landing.pricing.basic.h4')}
                            </button>
                        </li>
                        <li className="nav-item">
                            <button className={`${tabIndex === 2 ? 'active' : ''}`} onClick={() => setTabIndex(2)}>
                                <img src="/images/tabs-first-icon.png" width={35} height={35} alt="" />
                                {t('landing.pricing.premium.h4')}
                            </button>
                        </li>
                    </ul>
                    <section className="tabs-content">
                        <article className={`${tabIndex === 0 ? 'active' : ''}`}>
                            <div className="row">
                                <div className="col-12 col-md-5">
                                    <ul>
                                        <li>{t('landing.pricing.free.li1')}</li>
                                        <li>{t('landing.pricing.free.li2')}</li>
                                        <li>{t('landing.pricing.free.li3')}</li>
                                        <li><strong>{t('landing.pricing.free.li4')}</strong></li>
                                    </ul>
                                    <div className="mt-4 mb-4 mb-md-0 main-button">
                                        <Link to="/subscribe#free">{t('landing.pricing.selectPlan')}</Link>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <img className="img-fluid" src="/images/training-image-01.jpg" width={730} height={310} alt={t('landing.pricing.free.h4')} />
                                </div>
                            </div>
                        </article>
                        <article className={`${tabIndex === 1 ? 'active' : ''}`}>
                            <div className="row">
                                <div className="col-12 col-md-5">
                                    <ul>
                                        <li>{t('landing.pricing.basic.li1')}</li>
                                        <li>{t('landing.pricing.basic.li2')}</li>
                                        <li>{t('landing.pricing.basic.li3')}</li>
                                        <li>{t('landing.pricing.basic.li4')}</li>
                                        <li><strong>{t('landing.pricing.basic.li5')}</strong></li>
                                    </ul>
                                    <div className="mt-4 mb-4 mb-md-0 main-button">
                                        <Link to="/subscribe#basic">{t('landing.pricing.selectPlan')}</Link>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <img className="img-fluid" src="/images/training-image-02.jpg" width={730} height={310} alt={t('landing.pricing.basic.h4')} />
                                </div>
                            </div>
                        </article>
                        <article className={`${tabIndex === 2 ? 'active' : ''}`}>
                            <div className="row">
                                <div className="col-12 col-md-5">
                                    <ul>
                                        <li>{t('landing.pricing.premium.li1')}</li>
                                        <li>{t('landing.pricing.premium.li2')}</li>
                                        <li>{t('landing.pricing.premium.li3')}</li>
                                        <li>{t('landing.pricing.premium.li4')}</li>
                                        <li><strong>{t('landing.pricing.premium.li5')}</strong></li>
                                    </ul>
                                    <div className="mt-4 mb-4 mb-md-0 main-button">
                                        <Link to="/subscribe#premium">{t('landing.pricing.selectPlan')}</Link>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <img className="img-fluid" src="/images/training-image-03.jpg" width={730} height={310} alt={t('landing.pricing.premium.h4')} />
                                </div>
                            </div>
                        </article>
                    </section>
                </div>
            </section>
            <section className="section" id="contact-us">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="section-heading">
                                <h2>{t('landing.contactUs.h2')}</h2>
                                <img src="/images/line-dec.png" width={45} height={15} alt="" />
                            </div>
                            <div className="contacts">
                                <p>
                                    <a href="mailto:hello@wlog.lt">hello@wlog.lt</a>
                                    <br />
                                    <a className="me-2" href="https://www.facebook.com/WLog">
                                        <i className="mt-2 fs-2 fab fa-facebook" />
                                    </a>
                                    <a className="me-2" href="https://www.instagram.com/wlog.lt/">
                                        <i className="mt-2 fs-2 fab fa-instagram" />
                                    </a>
                                    <a href="https://twitter.com/wlog.lt">
                                        <i className="mt-2 fs-2 fab fa-twitter" />
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="contact-form">
                                <form id="contact" onSubmit={(e) => handleSubmit(e)}>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <input value={name} placeholder={t('landing.contactUs.name')} aria-label={t('landing.contactUs.name')} required={true} onChange={(e) => setName(e.target.value)} onFocus={() => setLoadRecaptcha(true)} />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <input type="email" value={email} pattern="[^ @]*@[^ @]*" placeholder={t('landing.contactUs.email')} aria-label={t('landing.contactUs.email')} required={true} onChange={(e) => setEmail(e.target.value)} onFocus={() => setLoadRecaptcha(true)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <input value={subject} placeholder={t('landing.contactUs.subject')} aria-label={t('landing.contactUs.subject')} required={true} onChange={(e) => setSubject(e.target.value)} onFocus={() => setLoadRecaptcha(true)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <textarea rows="6" value={message} placeholder={t('landing.contactUs.message')} aria-label={t('landing.contactUs.message')} required={true} onChange={(e) => setMessage(e.target.value)} onFocus={() => setLoadRecaptcha(true)}></textarea>
                                            {errors.includes('sent') &&
                                                <div className="valid-feedback mb-3">{t('Your message was sent! We will contact you as soon as possible.')}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 col-md-3 col-lg-2">
                                            <button type="submit" id="form-submit" className="main-button">{t('landing.contactUs.sendMessage')}</button>
                                        </div>
                                        <div className="col-12 col-md-9 col-lg-10 mt-3 mt-md-0 overflow-auto">
                                            <Recaptcha render="explicit" sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY} onloadCallback={() => {}} verifyCallback={verifyCallback} />
                                            {errors.includes('recaptcha') &&
                                                <div className="invalid-feedback">{t('Please validate that you are human!')}</div>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
