import React from 'react';

function Loading() {
    return (
        <div className="loading">
            <div className="loading-inner">
                <span className="dot" />
                <div className="dots">
                    <span />
                    <span />
                    <span />
                </div>
            </div>
        </div>
    )
}

export default Loading;
