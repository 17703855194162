import React from 'react';
import { Link } from 'react-router-dom';

import { config } from '../../config';

function Footer(props) {
    const t = props.t;

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <p>&copy; {t('title')} {config.version}</p>
                        <p>
                            <Link className="me-1" to="/tos">{t('Terms of service')}</Link>
                            <Link className="ms-1" to="/privacy">{t('Privacy policy')}</Link>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
