// Import scripts
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import axios from 'axios';
import { config } from './config';
import { resolveApiErrorCode } from './common';
import { OffCanvas, OffCanvasMenu, OffCanvasBody } from 'react-offcanvas';
import { withTranslation } from "react-i18next";

import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/lt';

// Import app components
import Header from './Components/Common/Header';
import Loading from './Components/Common/Loading';
import NavigationHead from './Components/Common/NavigationHead';
import Navigation from './Components/Common/Navigation';
import Footer from './Components/Common/Footer';
import Home from './Components/Home/Home';

// Import css
import './App.scss';

// Lazyload components
const Workouts = lazy(()=> import('./Components/Workouts/Workouts'));
const Workout = lazy(()=> import('./Components/Workout/Workout'));
const WorkoutStart = lazy(()=> import('./Components/WorkoutStart/WorkoutStart'));
const WorkoutActive = lazy(()=> import('./Components/WorkoutActive/WorkoutActive'));
const Programs = lazy(()=> import('./Components/Programs/Programs'));
const ProgramCreate = lazy(()=> import('./Components/ProgramCreate/ProgramCreate'));
const Program = lazy(()=> import('./Components/Program/Program'));
const Weight = lazy(()=> import('./Components/Weight/Weight'));
const Profile = lazy(()=> import('./Components/Profile/Profile'));
const Login = lazy(()=> import('./Components/Login/Login'));
const SignUp = lazy(()=> import('./Components/SignUp/SignUp'));
const ResetPassword = lazy(()=> import('./Components/ResetPassword/ResetPassword'));
const Stats = lazy(()=> import('./Components/Stats/Stats'));
const Tos = lazy(()=> import('./Components/Static/Tos'));
const Privacy = lazy(()=> import('./Components/Static/Privacy'));
const TwitterCallback = lazy(()=> import('./Components/Login/TwitterCallback.js'));
const FbDataDeletionStatus = lazy(()=> import('./Components/Static/FbDataDeletionStatus'));
const Subscribe = lazy(()=> import('./Components/Subscribe/Subscribe'));
const GuidedPrograms = lazy(()=> import('./Components/Static/Features/GuidedPrograms.js'));
const WeightTracking = lazy(()=> import('./Components/Static/Features/WeightTracking.js'));
const InteractiveWorkouts = lazy(()=> import('./Components/Static/Features/InteractiveWorkouts.js'));
const Statistics = lazy(()=> import('./Components/Static/Features/Statistics.js'));

function Page(props) {
    const { t, i18n } = props;

    const [loading, setLoading] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);
    const [account, setAccount] = useState(JSON.parse(localStorage.getItem('account')) || false);
    const [pages, setPages] = useState(null);
    const [limit, setLimit] = useState(null);
    const [settings, setSettings] = useState(null);
    const [subscription, setSubscription] = useState(false);
    const [activeWorkout, setActiveWorkout] = useState("false");
    const [workouts, setWorkouts] = useState(null);
    const [programs, setPrograms] = useState(null);
    const [weight, setWeight] = useState(null);

    // Set default locale
    moment.locale('lt');

    useEffect(() => {
        handleLoadCookiebot();

        window.addEventListener('CookiebotOnAccept', function (e) {
            if (window.Cookiebot.consent.statistics) {
                handleLoadGoogleTag();
            }

            if (window.Cookiebot.consent.marketing) {
                handleLoadGoogleAds();
            }
        });

        async function fetchData() {
            if (!account) {
                setLoading(false);
                return;
            }

            const url = config.api.baseUrl + 'user-data?accessToken=' + account.accessToken;

            setLoading(true);
            axios.get(url).then(apiResponse => {
                if (apiResponse !== undefined && apiResponse.data.status === 'OK') {
                    // Set user preferred language
                    if (apiResponse.data.data.settings.language) {
                        i18n.changeLanguage(apiResponse.data.data.settings.language);
                        localStorage.setItem('language', apiResponse.data.data.settings.language);
                        switch(apiResponse.data.data.settings.language) {
                            case 'en':
                                moment.locale('en-gb');
                                break;
                            default:
                                moment.locale(apiResponse.data.data.settings.language);
                                break;
                        }
                    }

                    setPages(apiResponse.data.pages)
                    setLimit(apiResponse.data.limit)
                    setSettings(apiResponse.data.data.settings);
                    setSubscription(apiResponse.data.data.subscription);
                    setActiveWorkout(apiResponse.data.data.activeWorkout);
                    setWorkouts(apiResponse.data.data.workouts)
                    setPrograms(apiResponse.data.data.programs)
                    setWeight(apiResponse.data.data.weight)
                    setLoading(false);
                } else if (apiResponse !== undefined && apiResponse.data.status === 'FAILURE' && apiResponse.data.errors) {
                    resolveApiErrorCode(apiResponse.data.errors);
                }
            }).catch(e => {
                console.log(e);
            });
        }

        fetchData();
    }, [i18n, account]);

    const handleLoadCookiebot = () => {
        const script = document.createElement('script');
        script.id = 'Cookiebot';
        script.src = 'https://consent.cookiebot.com/uc.js';
        script.dataset.cbid = 'e3d2d997-6c09-41f4-82d4-a2af03143746';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }

    const handleLoadGoogleTag = () => {
        const script = document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-NJWYWH8NE7';
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        window.gtag = function() {
            window.dataLayer.push(arguments);
        }

        window.gtag('js', new Date());
        window.gtag('config', 'G-NJWYWH8NE7');
    }

    const handleLoadGoogleAds = () => {
        const script = document.createElement('script');
        script.id = 'GoogleAdSense';
        script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6767238182073031';
        script.crossOrigin = 'anonymous';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }

    const handleMenuOpen = () => {
        setMenuOpen(!menuOpen);
    }

    const handleLoading = (value) => {
        setLoading(value);
    }

    const handleLogout = async () => {
        const url = config.api.baseUrl + 'logout?accessToken=' + account.accessToken;

        setLoading(true);
        axios.get(url).then(apiResponse => {
            if (apiResponse !== undefined && apiResponse.data.status === 'OK') {
                localStorage.removeItem('account');
                setAccount(false);
            }
        }).catch(e => {
            console.log(e);
        });
    }

    var commonProps = {
        t,
        i18n,
        loading,
        handleLoading,
        menuOpen,
        handleMenuOpen,
        account,
        settings,
        subscription,
        pages,
        limit,
    };

    if (loading) {
        return (
            <div className="app">
                <div className="headroom">
                    <Header t={t} i18n={i18n} account={account} handleMenuOpen={handleMenuOpen} menuOpen={menuOpen} handleLogout={handleLogout} />
                </div>
                <Loading />
            </div>
        )
    }

    return (
        <div className="app">
            <MetaTags>
                <title>{t('meta.title')}</title>
                <meta property="og:title" content={t('meta.title')} />
            </MetaTags>
            <div className="headroom">
                <Header t={t} i18n={i18n} account={account} handleMenuOpen={handleMenuOpen} menuOpen={menuOpen} handleLogout={handleLogout} />
            </div>

            <section className="wrapper">
                <OffCanvas width={window.innerWidth >= 768 ? 300 : 200} transitionDuration={300} isMenuOpened={menuOpen} position={'left'} effect={'overlay'}>
                    <OffCanvasBody className="content">
                        <Routes>
                            <Route path="workouts" element={ <Suspense fallback={<Loading />}><Workouts {...commonProps} workouts={workouts} activeWorkout={activeWorkout} /></Suspense> } />
                            <Route path="workout/:id" element={ <Suspense fallback={<Loading />}><Workout {...commonProps} /></Suspense> } />
                            <Route path="workout-start" element={ <Suspense fallback={<Loading />}><WorkoutStart {...commonProps} programs={programs} /></Suspense> } />
                            <Route path="workout-active" element={ <Suspense fallback={<Loading />}><WorkoutActive {...commonProps} /></Suspense> } />
                            <Route path="programs" element={ <Suspense fallback={<Loading />}><Programs {...commonProps} programs={programs} /></Suspense> } />
                            <Route path="program-create" element={ <Suspense fallback={<Loading />}><ProgramCreate {...commonProps} programs={programs} /></Suspense> } />
                            <Route path="program/:id" element={ <Suspense fallback={<Loading />}><Program {...commonProps} /></Suspense> } />
                            <Route path="weight" element={ <Suspense fallback={<Loading />}><Weight {...commonProps} weight={weight} /></Suspense> } />
                            <Route path="profile" element={ <Suspense fallback={<Loading />}><Profile {...commonProps} /></Suspense> } />
                            <Route path="login" element={ <Suspense fallback={<Loading />}><Login {...commonProps} setAccount={setAccount} /></Suspense> } />
                            <Route path="reset-password/:token" element={ <Suspense fallback={<Loading />}><ResetPassword {...commonProps} /></Suspense> } />
                            <Route path="reset-password" element={ <Suspense fallback={<Loading />}><ResetPassword {...commonProps} /></Suspense> } />
                            <Route path="sign-up" element={ <Suspense fallback={<Loading />}><SignUp {...commonProps} /></Suspense> } />
                            <Route path="stats" element={ <Suspense fallback={<Loading />}><Stats {...commonProps} programs={programs} /></Suspense> } />
                            <Route path="tos" element={ <Suspense fallback={<Loading />}><Tos {...commonProps} /></Suspense> } />
                            <Route path="privacy" element={ <Suspense fallback={<Loading />}><Privacy {...commonProps} /></Suspense> } />
                            <Route path="twitter-callback" element={ <Suspense fallback={<Loading />}><TwitterCallback {...commonProps} setAccount={setAccount} /></Suspense> } />
                            <Route path="facebook-data-deletion/:code" element={ <Suspense fallback={<Loading />}><FbDataDeletionStatus {...commonProps} /></Suspense> } />
                            <Route path="subscribe" element={ <Suspense fallback={<Loading />}><Subscribe {...commonProps} /></Suspense> } />
                            <Route path="features/guided-programs" element={ <Suspense fallback={<Loading />}><GuidedPrograms {...commonProps} /></Suspense> } />
                            <Route path="features/weight-tracking" element={ <Suspense fallback={<Loading />}><WeightTracking {...commonProps} /></Suspense> } />
                            <Route path="features/interactive-workouts" element={ <Suspense fallback={<Loading />}><InteractiveWorkouts {...commonProps} /></Suspense> } />
                            <Route path="features/statistics" element={ <Suspense fallback={<Loading />}><Statistics {...commonProps} /></Suspense> } />
                            <Route index element={ <Home {...commonProps} /> } />
                        </Routes>
                    </OffCanvasBody>
                    <OffCanvasMenu className="menu">
                        <NavigationHead {...commonProps} />
                        <Navigation {...commonProps} handleLogout={handleLogout} />
                    </OffCanvasMenu>
                </OffCanvas>
            </section>
            <Footer t={t} />
        </div>
    )
}

const PageTranslated = withTranslation('app')(Page)

function App() {
    return (
        <PageTranslated />
    );
}

export default App;
